.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.player-wrapper:fullscreen {
  padding-top: 0 !important;
}
video {
  border-radius: 10px;
  object-fit: inherit;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  //   padding: 2rem;
  //   margin: 2rem;
  &__preview {
    // padding: 0 2rem;
    border-radius: 10px;
  }
  // &__shadow {
  //   // padding: 0 2rem;
  //   // border-radius: 10px;
  // }
  &--mirrored {
    video {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg); /* Safari and Chrome */
      -moz-transform: rotateY(180deg); /* Firefox */
    }
  }
  // &--compare-open {
  //   video {
  //     object-fit: cover;
  //     border-radius: 10px 0 0 10px;
  //   }
  // }

  // #mirrored{
  //   video {
  //     transform: rotateY(180deg);
  //     -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  //     -moz-transform: rotateY(180deg); /* Firefox */
  //   }
  // }
}
.video-next-set-box {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #011523;

  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-size: 60px;
    color: #3ab549;
  }
}
// .react-player-intro-up {
//   z-index: 9;
// }
// .react-player-intro-down {
//   z-index: -9;
// }

.webcam-player {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  video {
    object-fit: cover;
    // border-radius: 10px 0px 0px 10px;
  }
}

.video-controls {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column-reverse;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 10px;
  transition: 0.2s;
  // .material-icons {
  //   cursor: pointer;
  // }
  &__bottom {
    opacity: 0;
    // opacity: 1;
    transition: 0.2s;
  }
  &__slider {
    opacity: 0;
    // opacity: 1;
    transition: 0.2s;
  }
  &__popover {
    opacity: 1;

    color: #fff;
    transition: 0.2s;
    width: 228px;
    position: absolute;
    bottom: 90px;
    right: 1rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1px;
    &__section {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      background-color: rgba(0, 0, 0, 0.5);
      &--top {
        border-radius: 10px 10px 0 0;
      }
      &--bottom {
        border-radius: 0 0 10px 10px;
      }
    }
    &__section:hover {
      background-color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
    }

    &--closed {
      opacity: 0;
      z-index: -1;
      .video-controls__popover__section:hover {
        display: none;
        background-color: rgba(0, 0, 0, 0);
        cursor: default;
      }
    }
  }

  &--open {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    .video-controls__bottom {
      z-index: 2;
      opacity: 1;
    }
    .video-controls__slider {
      z-index: 2;
      opacity: 1;
    }
  }

  &__flip-icon {
    transition: 0.4s;
  }
  &__flip-icon--flipped {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }

  &__compare-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 30px;
    z-index: 3;
  }
}
// .video-controls:hover {
//   background-color: rgba(0, 0, 0, 0.3);
//   z-index: 1;
//   .video-controls__bottom {
//     opacity: 1;
//   }
//   .video-controls__slider {
//     opacity: 1;
//   }
// }
.video-controls__bottom {
  display: flex;
  justify-content: space-between;
  &__left {
    display: flex;
    gap: 25px;
  }
  &__right {
    display: flex;
    gap: 25px;
  }
  span {
    user-select: none;
  }
  .control-icon {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}

// .video_container {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// }

// .player__wrapper {
//   position: relative;
// }

// .player {
//   border: 2px solid #7b2cbf;
//   object-fit: cover;
//   padding: 0;
//   margin: 0;
// }

// h2 {
//   color: #7b2cbf;
// }

.video-controls-mobile {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 0;

  border-radius: 10px;
  transition: 0.2s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .control-icon {
    // display: flex;
    // flex-direction: row;
    cursor: pointer;
    position: relative;
    z-index: 2;
    // width: 25px;
    // height: 25px;
  }
  &__bottom {
    opacity: 0;
    // opacity: 1;
    transition: 0.3s;

    display: flex;
    flex-direction: column;
  }
  &__middle {
    padding: 10px 15px;
    opacity: 0;
    // opacity: 1;
    transition: 0.3s;
    display: flex;
    justify-content: space-evenly;
  }
  &__top {
    padding: 10px 15px;
    opacity: 0;
    // opacity: 1;
    transition: 0.3s;
    display: flex;
    justify-content: end;
  }
  &__bottom-controls {
    padding: 0px 15px;

    display: flex;
    justify-content: space-between;
  }
  &__slider {
    opacity: 0;

    transition: 0.3s;
    display: flex;
    flex-direction: column-reverse;
  }
  &__popover {
    opacity: 1;

    color: #fff;
    transition: 0.1s;
    width: 228px;
    position: absolute;
    bottom: -95px;
    left: 0;

    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1px;
    &__section {
      display: flex;
      justify-content: space-between;
      padding: 12px;

      backdrop-filter: blur(40px);
      -webkit-backdrop-filter: blur(40px); //for safari
      background-color: rgba(0, 0, 0, 0.5);
      &--top {
        border-radius: 10px 10px 0 0;
      }
      &--bottom {
        border-radius: 0 0 10px 10px;
      }
    }
    &__section:hover {
      background-color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
    }

    &--closed {
      opacity: 0;
      z-index: -1;
      left: -260px;
      .video-controls-mobile__popover__section:hover {
        display: none;
        background-color: rgba(0, 0, 0, 0);
        cursor: default;
      }
    }
  }

  &--open {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    .video-controls-mobile__bottom {
      position: relative;
      opacity: 1;
    }
    .video-controls-mobile__slider {
      position: relative;
      z-index: 2;
      opacity: 1;
    }
    .video-controls-mobile__middle {
      // z-index: 2;
      position: relative;
      opacity: 1;
    }
    .video-controls-mobile__top {
      position: relative;
      opacity: 1;
    }
  }

  &__flip-icon {
    transition: 0.4s;
  }
  &__flip-icon--flipped {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
  .MuiSlider-root {
    // @media (pointer: coarse) {
    // .css-1094jw2-MuiSlider-root {
    padding: 0 !important;
    // }
    // }
  }
}

.practice-mode-controls {
  select {
    font-size: 14px;
  }
  // justify-content: center !important;
  // align-items: center !important;
  position: absolute;
  // text-align: center;
  // bottom: 50%;
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  left: 0;
  bottom: 0;
  // justify-content: center;

  // left: 45%;
  // border-radius: 50%;
  // background-color: green;

  // &__section {
  //   display: flex;
  //   justify-content: space-between;
  // }
  &__countdown {
    font-size: 200px;
    opacity: 0.5;
  }
  &__middle-section {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    img {
      width: 60px;
    }
  }
  &__bottom-section {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 30px;
  }
  &__bottom-controls {
    width: 100%;
    height: 100px;
    // margin: 30px;

    // padding-inline: 4rem;
    display: flex;
    justify-content: center;
    gap: 1px;
    align-items: center;
    // border-radius: 10px;
    &__section {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px); //for safari
      background-color: rgba(0, 0, 0, 0.1);
      height: 100%;
      padding: 1rem;
      &--left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 90px;
      }
      // &--middle {
      //   // nothingyet, maybe width adjustments
      // }
      &--right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 90px;
      }
    }
  }
  &__rep-counter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    font-weight: bold;
  }
  &__bottom-center-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    &__right {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__set-tracker {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  }
  &__set-marker {
    width: 48px;
    height: 6px;
    border-radius: 4px;
    background-color: white;

    transition: 0.3s;
    &--completed-set {
      background-color: #3ab549;
    }
  }
  &__play-pause {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 559px) {
  .practice-mode-controls {
    &__countdown {
      font-size: 50px !important;
    }
    select {
      font-size: 9px;
    }
    &__bottom-section {
      padding: 0;
    }
    &__bottom-controls {
      height: 50px;
      &__section {
        // backdrop-filter: blur(10px);
        // -webkit-backdrop-filter: blur(10px); //for safari
        // background-color: rgba(0, 0, 0, 0.1);
        // height: 100%;
        // padding: 1rem;
        &--left {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: 50px;
        }
        &--middle {
          //nothingyet, maybe width adjustments
          font-size: 11px;
        }
        &--right {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          width: 50px;
        }
      }
    }

    &__rep-counter {
      font-size: 30px;
    }
    &__set-marker {
      width: 6px;
    }
  }
  .video-controls__compare-button {
    padding: 0;
    button {
      font-size: 10px;
      padding: 3px;
    }
  }

  .video-next-set-box {
    h1 {
      font-size: 30px;
    }
  }
}
