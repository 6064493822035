.results-slider {
  //   .swiper-button-next {
  //     color: red !important;
  //   }
  height: auto;
  position: relative;
  margin-bottom: 45px;
  p {
    font-size: 18px;
  }
  //   &__end-shadow {
  //     position: absolute;
  //     // box-shadow: inset 5px 0 5px -5px black;
  //     background-color: black;
  //     opacity: 0.4;
  //     height: 135px;
  //     width: 80px;
  //     right: 0;
  //     top: 0;
  //     z-index: 8;
  //   }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 769px) {
    .swiper-slide:first-child {
      transition: transform 100ms;
    }

    .swiper-slide:first-child img {
      transition: box-shadow 500ms;
    }

    .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(50%);
      z-index: 2;
    }

    .swiper-slide.swiper-slide-active:first-child img {
      box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.35);
    }

    .swiper-slide:nth-child(2) {
      transition: transform 100ms;
    }

    .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(55%);
      z-index: 1;
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(-50%);
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(-55%);
    }
  }

  &__thumbnail {
    width: 240px;
    height: 150px;
  }
}

.button-slider {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 559px) {
  .results-slider__thumbnail {
    width: 28vw;
    height: 38vw;
    // width: 110px;
    // height: 150px;
    p {
      font-size: 14px;
    }
  }
  .button-slider {
    display: flex;
    // justify-content: space-between;
    gap: 12px;
    overflow-x: scroll;
    button {
      min-width: 120px;
      font-size: 14px;
      padding: 0px 12px;
    }
  }

  .button-slider::-webkit-scrollbar {
    display: none;
  }
}

.programs-slider {
  &__card {
    background-color: var(--backgroundSecond);
    width: 424px;
    height: 150px;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    &__thumb {
      width: 120px;
      height: 120px;
      // box-shadow: inset 0 -5px 2em 5px #000;
      margin: auto 0;
      border-radius: 3px;
      background-position: center;
      background-size: cover;
    }
    &__textbox {
      display: flex;
      flex-direction: column;
      margin: auto 0;
      // justify-content: end;
      // gap: 8px;
      width: 60%;
      // width: 200px;
      font-size: 14px;
    }
    &__title {
      font-size: 25px;
      &--small {
        font-size: 21px;
      }
    }
  }
}
.time-swiper {
  display: flex;
  height: 100px;
  justify-content: center;
  position: relative;
  .swiper {
    height: 100px;
    margin: 0 19px;
  }
  .swiper-slide {
    height: auto !important;
    opacity: 0.6;
  }
  .swiper-slide-active {
    opacity: 1;
  }
  &__center-bar-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    &__bar {
      position: absolute;
      width: 332px;
      height: 28px;
      background-color: #001f33;
    }
  }
}
