.toasts {
  min-width: 280px;
  position: fixed;
  z-index: 100;
}

.toast {
  align-items: center;
  background-color: white;
  border-left: 12px solid gray;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 1rem;
  width: 100%;
  animation: toasting 5s;

  &--info {
    border-left-color: #0071bb;
  }
  &--error {
    border-left-color: red;
  }
  &--success {
    border-left-color: #3ab549;
  }
  &--warning {
    border-left-color: orange;
  }
}

@keyframes toasting {
  0% {
    left: -300px;
    opacity: 0;
  }
  5% {
    left: 30px;
    opacity: 1;
  }
  95% {
    left: 30px;
    opacity: 1;
  }
  100% {
    left: -300px;
    opacity: 0;
  }
}
