.admin-area-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--backgroundSecond);
}

.admin-area-body {
  height: 100%;
  max-height: 990px;
  width: 100%;
  display: flex;
  justify-content: center;
  // margin: 1rem 0 0 0;
}
.admin-action-container {
  // height: 864px;
  // height: 1000px;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  // max-height: 1000px;
}

.admin-action {
  width: 100%;
  background-color: var(--backgroundSecond);
  //   margin-right: 2rem;
  height: 100%;
  border-radius: 5px;
  // padding: 1rem;
}

.admin-action-view {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
  justify-content: space-between;

  &__edit-button {
    width: 100%;
    display: flex;
    justify-content: right;
    //THE ABSOLUTE WAS ADDED 29 AUG 24
    position: absolute;
    // z-index: 1;
    button {
      z-index: 1;
      background-color: transparent !important;
    }
  }
  &__profile-pic {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 50%;
      border-radius: 50%;
      border: 5px solid var(--primary);
    }
    &--edit {
      position: relative;
      button {
        position: absolute;
        z-index: 1;
        height: 40px;
        // width: 40px;
      }
    }
  }
}
.admin-overflow-scroll {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  &--centered {
    justify-content: center;
  }
}
.admin-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  label {
    font-size: 14px;
  }
  input {
    width: 100%;
    height: 45px;
    // margin: 2rem 0;
  }
  textarea {
    width: 100%;
  }

  &__character-counter {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    line-height: 11px;
  }
}
.admin-radio-button {
  display: flex;
  align-items: center;
  input {
    height: 18px !important;
    width: 18px !important;
  }
}
.admin-vid-thumbnail-switch {
  display: flex;
  align-items: center;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  // padding-inline: 2rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  gap: 4px;
  transition: 0.2s;
  i {
    font-size: 20px;
  }
}
.admin-vid-thumbnail-switch:hover {
  cursor: pointer;
  opacity: 0.7;
}
.admin-edit-item-button {
  display: flex;
  align-items: center;
  font-size: 18px;
  position: absolute;
  right: 0;
  bottom: 0;
  // padding-inline: 2rem;
  padding-bottom: 0.5rem;
  gap: 4px;
  transition: 0.2s;
  i {
    font-size: 18px;
  }
}
.admin-edit-item-button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.admin-edit-thumbnail-button {
  display: flex;
  align-items: center;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  // padding-inline: 2rem;
  margin: 1.5rem 2rem 0 0;
  // padding-bottom: 11rem;
  // padding-left: 14rem;
  gap: 4px;
  transition: 0.2s;
  opacity: 0.8;
  z-index: 1;
  i {
    font-size: 18px;
  }
}
.admin-edit-thumbnail-button:hover {
  cursor: pointer;
  opacity: 1;
}
.admin-info-half {
  width: 100%;
  padding: 1rem;
  // display: flex;
  // flex-direction: column;
  // overflow-y: scroll;
  &__title {
    // height: 60px;
    display: flex;
    align-items: center;
    padding: 1rem 0 0.5rem 0;
    font-size: 24px;
    font-weight: bold;
    justify-content: space-between;
    position: relative;
  }
  &__section-title {
    font-size: 20px;
    font-weight: bold;
    position: relative;
  }
  &__thumbnailbox {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    gap: 3px;

    background-color: var(--cardBackground);
    border-radius: 5px;
    padding: 1rem;
    img {
      border-radius: 5px;
      width: 100%;
    }
    margin-bottom: 1rem;
    transition: 0.2s;
  }
  &__thumbnailbox--video {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg); /* Safari and Chrome */
    -moz-transform: rotateY(360deg); /* Firefox */
  }
  &__infobox {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 3px;

    background-color: var(--cardBackground);
    border-radius: 5px;
    padding: 1rem;
  }
  &__key-value {
    display: flex;
    // border-bottom: 3px solid white;
    // justify-content: space-between;
    padding: 4px 16px;
    white-space: nowrap;
    // background-color: var(--backgroundBase);
    border-radius: 5px;
    label {
      font-weight: bold;
      width: 30%;
    }
    strong {
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__description {
    font-size: 12px;
    padding: 4px 16px;
    // background-color: var(--backgroundBase);
    border-radius: 5px;
    label {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.admin-player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.admin-react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.admin-table-half {
  width: 100%;
  padding: 1rem;
  &__title {
    // height: 60px;
    display: flex;
    align-items: center;
    padding: 1rem 0 0.5rem 0;
    font-size: 24px;
    font-weight: bold;
    justify-content: space-between;
    position: relative;
  }
}
.admin-action-edit-screen {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: var(--backgroundSecond);
  bottom: -1020px;
  transition: 0.2s;

  display: flex;
  //CHANGING FLEX DIRECTION TO TEST NEW LESSONS, REVERT TO COLUMN IN MOBILE
  // flex-direction: row-reverse;
  // flex-direction: column;
  // gap: 1rem;
  &--open {
    bottom: 0;
  }
  // input {
  //   width: 100%;
  //   height: 45px;
  //   // margin: 2rem 0;
  // }
}
.admin-video-cats-wrapper {
  background-color: var(--cardBackground);
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  &__title {
    padding-bottom: 8px;
    position: relative;
  }
}
.admin-video-cats {
  height: 400px;

  overflow-y: scroll;
  font-size: 14px;
  display: grid;

  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));

  grid-auto-rows: auto;
  &__cat-boxes {
    // width: auto;
    display: flex;
    flex-direction: column;
  }
  &__parent {
    font-weight: bold;
  }
  &__child {
    padding: 0.5rem;
    &__child-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        height: 18px;
        width: 18px;
      }
    }
  }
}
.admin-quick-fix {
  background-color: var(--cardBackground);
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  &__title {
    padding-bottom: 6px;
    font-size: 20px;
    position: relative;
    font-weight: bold;
  }
  .admin-info-half__thumbnailbox {
    background-color: var(--secondary);
  }
}
.admin-quick-fix-list {
  max-height: 400px;
  overflow-y: scroll;
}
.picker-dropdown {
  margin: 2rem auto;
  select {
    height: 50px;
    border: none;
    // background-color: var(ba);
    // color: white;
    // padding: 1rem;
    // line-height: 3rem;
    font-size: 20px;
  }
  // option {
  //   // min-height: 2rem !important;
  //   padding: 8px;
  // }

  // .option {
  //   // height: 40px !important;
  //   padding: 2rem;
  // }
}
.admin-sub-video-section {
  background-color: var(--cardBackground);
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  &__title {
    padding-bottom: 6px;
    font-size: 20px;
    position: relative;
    font-weight: bold;
  }
  .admin-info-half__thumbnailbox {
    background-color: var(--secondary);
    padding-bottom: 0.25rem;
  }
}
.admin-sub-video-section-list {
  max-height: 400px;
  overflow-y: scroll;
}
.picker-dropdown {
  margin: 2rem auto;
  select {
    height: 50px;
    border: none;
    // background-color: var(ba);
    // color: white;
    // padding: 1rem;
    // line-height: 3rem;
    font-size: 20px;
  }
  // option {
  //   // min-height: 2rem !important;
  //   padding: 8px;
  // }

  // .option {
  //   // height: 40px !important;
  //   padding: 2rem;
  // }
}
.picker-list {
  height: 560px;
  overflow-y: scroll;
  &__element {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 4rem;
    font-size: 22px;
    border-radius: 5px;
    &--selected {
      //  rgb(11, 17, 32)
      background-color: var(--secondary) !important;
      // border: 2px solid var(--secondary);
    }
  }
  &__element:nth-child(odd) {
    background-color: var(--backgroundBase);
  }
  &__thumbnail {
    // width: 120px;
    height: 100%;
    img {
      // width: 100%;
      height: 100%;
    }
  }
}
.admin-overflow-scroll::-webkit-scrollbar {
  width: 14px;
}

.admin-overflow-scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.admin-overflow-scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.admin-overflow-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.picker-list__element:hover {
  cursor: pointer;
  background-color: rgb(11, 17, 32);
}

.picker-list::-webkit-scrollbar {
  width: 14px;
}

.picker-list::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.picker-list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.picker-list::-webkit-scrollbar-corner {
  background-color: transparent;
}
.admin-video-cats-wrapper::-webkit-scrollbar {
  // width: 14px;
  display: none;
}
.admin-video-cats::-webkit-scrollbar {
  // width: 14px;
  display: none;
}
.admin-info-half::-webkit-scrollbar {
  display: none;
}
.admin-quick-fix-list::-webkit-scrollbar {
  display: none;
}
.admin-sub-video-section-list::-webkit-scrollbar {
  display: none;
}
.admin-action-edit-screen::-webkit-scrollbar {
  // width: 14px;
  display: none;
}

.admin-file-upload-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  position: absolute;
  gap: 4px;
  transition: 0.2s;
  opacity: 0.8;
  z-index: 1;
  // width: 100%;
  white-space: nowrap;
  i {
    font-size: 26px;
  }
}
.admin-file-upload-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.drop-zone {
  height: 100% !important;
  width: 100% !important;
  border: 4px dashed var(--secondary) !important;
  padding: 1rem !important;
  span {
    font-size: 16px;
    font-weight: 600;
    color: #dddddd !important;
  }
}

.dragdrop-under-content {
  display: block;
  width: 100%;
  max-width: 460px;
  height: auto;
}
.dragdrop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white; /* Text color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure it stays on top of the image */
}
.drop-zone-content {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-golfballs-container {
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
}

.progress-golfballs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
}
.golfball-of-progress {
  width: 8%;
  height: auto;
  opacity: 0.3;
  transition: 0.2s;
  &--lit {
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .admin-overflow-scroll {
    height: calc(100vh - 50px);
    flex-direction: column;
    --centered {
      justify-content: initial;
    }
  }
  .admin-overflow-scroll--centered {
    .admin-info-half {
      height: 100%;
    }
  }

  .admin-table-half {
    height: 100%;
  }
  .admin-table-wrapper {
    max-height: calc(100vh - 200px) !important;
  }
  .admin-info-half__title,
  .admin-table-half__title {
    font-size: 18px;
  }
}

.admin-dash-card {
  background-color: var(--cardBackground);
  border-radius: 5px;
  max-height: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  // justify-content: space-evenly;
  &__link {
    font-size: 14px;
    width: 100%;
    padding: 0.5rem;
    background-color: var(--secondary);
    border-radius: 5px;
    transition: 0.1s;
  }
}
.admin-dash-card__link:hover {
  cursor: pointer;
  background-color: var(--backgroundSecond);
}
