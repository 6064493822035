// @use "./base/" as *;
// @use "./utils" as *;
@use "./components" as *;
@use "./ui" as *;
@use "./pages" as *;
// @use "./themes" as *;
//vendors
//utils
// LAST

.site {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
  &__body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}

.app-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  transition: 0.5s;
  background-color: var(--backgroundBase);
  color: var(--fontColor);
  height: 100vh;
}

.app {
  display: flex;

  min-height: 100vh;

  transition: 0.5s;
  width: 1920px;
  overflow: hidden;

  &__sidebar {
    flex: 0 0 420px;
  }
  &__body {
    flex: auto;
    overflow: hidden;

    height: 100%;
  }
  .material-icons {
    user-select: none;
  }
}
.admin-app-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  transition: 0.5s;
  background-color: var(--backgroundBase);
  color: var(--fontColor);
  height: 100vh;
}

.admin-app {
  display: flex;

  min-height: 100vh;

  transition: 0.5s;
  width: 1920px;
  overflow: hidden;

  &__sidebar {
    flex: 0 0 240px;
  }
  &__body {
    flex: auto;
    overflow: hidden;

    height: 100%;
  }
  .material-icons {
    user-select: none;
  }
}

@media (max-width: 559px) {
  .not-on-mobile {
    display: none;
  }

  .video-controls {
    display: none;
  }
  .video-pad-box {
    padding-inline: 0px !important;
  }
}
@media (min-width: 560px) {
  .only-on-mobile {
    display: none;
  }
  .video-controls-mobile {
    display: none;
  }
}

@media (max-width: 1079px) {
  .top-nav-temp-middle-right {
    display: none !important;
  }
  .navigation__top-app__middle {
    justify-content: center;
    width: 100%;
  }
  .navigation__top-app__right {
    display: none !important;
  }
  //temp above
  .app__sidebar {
    display: none;
  }

  .video-pad-box {
    padding-inline: 15px;
  }
  .navigation__top-app {
    height: auto;
    // padding: 1rem 0;
  }
  .admin-navigation {
    display: none;
  }
}
@media (min-width: 1080px) {
  .navigation__bottom {
    display: none;
  }
  // .only-on-mobile {
  //   display: none;
  // }
  .admin-navigation-mobile {
    display: none;
  }
}

@media (max-width: 1499px) {
  .container__side-content {
    display: none;
  }
  .navigation__top-app {
    .account {
      display: none;
    }
  }
  // .accordion {
  //   display: none;
  // }
}
@media (min-width: 1500px) {
  .container__bottom-content {
    display: none;
  }
  // .accordion-mobile {
  //   display: none;
  // }
}
:root.dark {
  --backgroundBase: #1a1a2e;
  // #011523 old backgroundbase
  --backgroundSecond: #16213e;
  --cardBackground: #0f3460;
  // --backgroundSecond: #00253c;
  //#00253C new potential 2nd color
  --fontColor: #ffffff;
}

:root {
  //no blue highlighting on mobile
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  --background-color: #fff;
  // --text-color: #000000;
  --primary: #3ab549ff;
  // formally dark-pastel-green
  --secondary: #0071bbff;
  // formally azul
  --black: #010101ff;
  --jet: #363636ff;
  --dim-gray: #707070ff;
  --white: #ffffffff;
  --pakistan-green: #134611ff;

  --penn-blue: #091540ff;

  --alice-blue: #e9edf4ff;
  --unnamed_green_bg: #f0f6f4ff;

  //new stuff

  --backgroundBase: white;
  --backgroundSecond: #363636;
  --fontColor: #000000;
}

//typography/fontsizes
.font {
  &--bold {
    font-weight: bold;
  }
  // &--
}
