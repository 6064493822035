.color_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.color_container > div {
    flex: 1;
    margin: 10px;
    min-height: 90px;
    min-width: 90px;
    max-width: 90px;
    border: solid 1px var(--black);
}