@use "../utils" as *;

@mixin button-base {
  color: var(--fontColor);
  font-size: 16px;
  padding: 0.5rem 2rem;
  //   padding: 8px 16px;
  border-radius: 25px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-decoration: none;
  border: none;
  cursor: pointer;
  min-width: 64px;
  display: inline-block;
  position: relative;
  text-align: center;
  transition: all 0.1s ease-in-out;
  font-weight: 500;

  &:disabled {
    color: $dim-gray;
    background-color: rgba(0, 0, 0, 0.12) !important;
    cursor: not-allowed;
    // &:hover {
    //   background-color: rgba(0, 0, 0, 0.346) !important;
    // }
    // &:active {
    //   background-color: rgba(0, 0, 0, 0.12) !important;
    // }
  }

  &.fluid {
    width: 100%;
  }
  .material-icons,
  .material-symbols,
  .material-symbols-outlined {
    display: block;
  }

  &.ss-icon-leading {
    padding-left: 2.5rem !important;
    .icon-leading {
      font-size: 20px;
      left: 12px;
      margin: 0;
      position: absolute;
      top: 9px;
    }
  }
  &.ss-icon-trailing {
    padding-right: 2.5rem !important;

    .icon-trailing {
      font-size: 20px;
      margin: 0;
      position: absolute;
      right: 12px;
      top: 10px;
    }
  }
}

.button {
  @include button-base;
}

.button-primary {
  @include button-base;
  background-color: $hg-primary;
  color: white;
  &:hover {
    background-color: #279623;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  &:active {
    background-color: #207c1d;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
.button-secondary {
  @include button-base;
  background-color: $hg-secondary;
  &:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  &:active {
    background-color: #104f97;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.button-breadcrumb {
  @include button-base;
  background: transparent;
}
// .button-breadcrumb.ss-icon-leading .icon-leading{}

// @media (max-width: 559px) {
// .button-primary {
// font-size: 14px;
// width: 64px;
// padding-inline: 12px;
// }
// }

.icon-text-button {
  display: flex;
  align-items: center;
  font-size: 18px;
}
