.program-schedule-maker {
  background-color: var(--backgroundSecond);
  width: 360px;
  //   height: 360px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  //   padding: 1rem;
  //   position: relative;
  &__switch-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }
  &__divider {
    width: 100%;
    // position: relative;
    // margin: 16px 0;
    border-bottom: 1px solid #011523;
  }
  &__weekdays-container {
    padding: 1rem;
    .tiny-label {
      color: #f0f6f3;
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
  &__weekdays {
    display: flex;
    justify-content: space-between;

    button {
      background-color: transparent;
      color: var(--fontColor);
      font-size: 12px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid var(--primary);
    }
    button.active {
      background-color: var(--primary);
    }
  }
  &__time-selector {
    padding: 1rem;
  }
  &__checkbox-container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 9px;
  }
}

.program-calendar {
  width: 360px;
  //   border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--backgroundSecond);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.calendar-header button {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  height: 100%;
  padding: 0;
}

.calendar-header span {
  font-size: 22px;
  font-weight: bold;
}

.calendar-days {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.calendar-day-label {
  flex: 1;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  color: #f0f6f3;
  //   font-weight: bold;
}

.calendar-dates {
  display: flex;
  flex-wrap: wrap;
}

.calendar-date {
  flex: 1 0 14.28%;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  position: relative;
  height: 40px;
  cursor: pointer;
}

.calendar-date-current {
  background-color: var(--backgroundBase);
  border-radius: 5px;
  height: 100%;
  //   color: #000;
}

.calendar-date-disabled {
  color: #cfcfcf;
  opacity: 0.7;
}

.calendar-date-dot {
  width: 9px;
  height: 9px;
  background-color: var(--primary);
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.session-selector {
  display: flex;
  flex-direction: column;
  width: 360px;
  &__nums {
    display: flex;
    justify-content: space-between;
  }
  &__num {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    width: 60px;
    height: 50px;
    border-radius: 10px;
    opacity: 0.5;
    &--selected {
      background-color: var(--primary);
      opacity: 1;
    }
  }
  &__activities {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__activity {
    background-color: var(--backgroundSecond);
    width: 360px;
    height: 70px;
    border-radius: 10px;
    display: flex;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__left {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      //   background-color: red;
      //   height: 100%;
    }
    &__right {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__middle {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1rem;
    }
    &__description {
      font-size: 12px;
      color: #f0f6f3;
    }
    &__title {
      font-weight: bold;
    }
  }
}
