.calendar-wrapper {
  h4 {
    color: #3ab549;
  }
}
.calendar {
  display: flex;
  width: 100%;
  //   justify-content: space-evenly;

  &__tile {
    width: 14%;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 0 2px;
    &--last {
      box-shadow: 0px 0px 20px rgba(90, 140, 157, 0.4);
    }
    svg {
      margin: 40px 0;
    }
  }
  &__dayAndDate {
    display: flex;
    // border-bottom: 1px solid rgba(173, 216, 230, 0.382);
    background-color: rgba(173, 216, 230, 0.136);
    p {
      margin: 2px 6px;
      font-size: 75%;
    }
  }
}

.categories {
  display: flex;
  width: 100%;
  margin: 2rem 0;
  justify-content: space-evenly;
  flex-direction: column;
  // background-color: rgba(128, 128, 128, 0.084);
  // padding: 2rem;
  border-radius: 6px;
  &__sections {
    h4 {
      margin: 0;
      color: #0071bb;
    }
  }
  &__sections:hover {
    cursor: pointer;
  }
}
.continue-watching {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  max-height: 300px;
  &__scroll {
    // height: 500px;
    overflow-y: scroll;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &__video {
    display: flex;
    // width: 60%;
    margin: 0 0 2rem 0;

    justify-content: left;
    img {
      width: 300px;
    }
    &--info {
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      h5 {
        margin: 1rem 0;
      }
    }
  }
  &__video:hover {
    cursor: pointer;
  }
}

.continue-watching__scroll::-webkit-scrollbar {
  width: 14px;
}
.continue-watching__scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.continue-watching__scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.continue-watching__scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.favorites {
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 0;
  //   width: 50%;
  max-height: 200px;

  &__scroll {
    // height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &__video {
    display: flex;
    // width: 60%;
    margin: 0 0 2rem 0;

    justify-content: left;
    img {
      width: 300px;
    }
    &--info {
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      h5 {
        margin: 1rem 0;
      }
    }
  }
  &__video:hover {
    cursor: pointer;
  }
}

.favorites__scroll::-webkit-scrollbar {
  width: 14px;
}
.favorites__scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.favorites__scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.favorites__scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.list {
  display: flex;
  width: 400px;
  overflow-x: scroll;
  &__video {
    margin: 0 1rem;
    img {
      border-radius: 10px;
      max-width: 200px;
    }
    p {
      font-size: x-small;
    }
  }
}

.list::-webkit-scrollbar {
  width: 14px;
}
.list::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.list::-webkit-scrollbar-corner {
  background-color: transparent;
}
.recommended {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  max-height: 300px;

  &__scroll {
    // height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &__video {
    display: flex;
    // width: 60%;
    margin: 0 0 2rem 0;

    justify-content: left;
    img {
      width: 300px;
    }
    &--info {
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      h5 {
        margin: 1rem 0;
      }
    }
  }
  &__video:hover {
    cursor: pointer;
  }
}

.recommended__scroll::-webkit-scrollbar {
  width: 14px;
}
.recommended__scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.recommended__scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.recommended__scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.results {
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem 0 0;
  //   width: 50%;
  // max-height: 200px;

  &__scroll {
    // height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &__video {
    display: flex;
    // width: 60%;
    margin: 0 0 2rem 0;

    justify-content: left;
    img {
      width: 150px;
    }
    &--info {
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      h5 {
        margin: 1rem 0;
      }
    }
  }
  &__video:hover {
    cursor: pointer;
  }
}

.results__scroll::-webkit-scrollbar {
  width: 14px;
}
.results__scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.results__scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.results__scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.rewatch {
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 0;
  //   width: 50%;
  max-height: 200px;

  &__scroll {
    // height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  &__video {
    display: flex;
    // width: 60%;
    margin: 0 0 2rem 0;

    justify-content: left;
    img {
      width: 300px;
    }
    &--info {
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      h5 {
        margin: 1rem 0;
      }
    }
  }
  &__video:hover {
    cursor: pointer;
  }
}

.rewatch__scroll::-webkit-scrollbar {
  width: 14px;
}
.rewatch__scroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.rewatch__scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.rewatch__scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.video-list {
  // display: flex;
  // flex-direction: column;

  &__fav-and-rewatch {
    margin: 0 0 4rem 0;
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
  }
}
