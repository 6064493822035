.admin-table-wrapper {
  // max-height: 726px;
  max-height: 606px;
  overflow-y: scroll;
  position: relative;
}
.admin-table-wrapper::-webkit-scrollbar {
  display: none;
}

.admin-table {
  font-size: 14px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
  overflow: hidden;

  td,
  th {
    border: none;
    text-align: left;
    // padding-left: 2rem;
  }
  th {
    padding-inline: 1rem;
  }

  td {
    height: 60px;
  }

  thead,
  tfoot {
    tr {
      background-color: var(--secondary);
    }
  }

  thead {
    tr {
      height: 60px;
    }
  }

  tbody {
    tr:hover {
      background-color: #0b1120;
      cursor: pointer;
    }
  }

  tr:nth-child(even) {
    background-color: var(--backgroundBase);
  }

  .table-thumbnail {
    img {
      width: 80px;
    }
  }

  .table-profile-pic {
    display: flex;
    padding: 3px 0;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primary);
    }
  }
  .table-position-image {
    display: flex;
    padding: 6px 0;
    img {
      width: 120px;
      height: auto;
      border-radius: 4px;
    }
  }
}

.admin-arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  // height: 68px;
  height: 100%;
  // background-color: var(--backgroundSecond);
  // border-right: 1px solid var(--backgroundBase);
  // border-radius: 5px;
  i {
    font-size: 50px;
    line-height: 0.6;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    // background-color: var(--backgroundBase);

    // background-color: #0b497d;
  }
  .can-click:hover {
    background-color: #0b497d;
    color: var(--primary);
    cursor: pointer;
  }
  .can-click:active {
    color: #174f1e;
  }

  // justify-content: space-around;
}

.admin-list-table {
  //   margin-bottom: 2rem;
  font-size: 14px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  //   border: 1px solid var(--secondary);
  border-radius: 5px;
  overflow: hidden;

  td,
  th {
    // padding: 1rem;
    padding-inline: 1rem;
    border: none;
    text-align: left;
  }
  td {
    height: 76px;
  }
  thead,
  tfoot {
    tr {
      background-color: var(--secondary);
    }
  }
  thead {
    tr {
      height: 74px;
    }
  }
  tbody {
    tr:hover {
      background-color: #0b1120;
      cursor: pointer;
    }
    // tr:nth-child(even):hover {
    //   background-color: #060912;
    // }
  }
  tr:nth-child(even) {
    background-color: var(--backgroundSecond);
  }
  .table-thumbnail {
    // width: 120px;
    img {
      width: 80px;
    }
  }
  .table-profile-pic {
    // width: 80px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primary);
    }
  }
  /* Optional: Adding rounded corners to the first and last row */
  thead tr:first-child th:first-child {
    //   border-top-left-radius: 10px;
  }
  &--secondary {
    thead {
      tr {
        background-color: var(--primary);
      }
    }
  }
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  background-color: var(--secondary);
  height: 60px;
  // position: fixed;
  // width: 1020px;
  width: 100%;
  &--secondary {
    background-color: var(--primary);
  }
}

.pagination-controls span {
  margin: 0 0.5rem;
}

.admin-list-table-container {
  position: relative;
  overflow-y: hidden;
  height: 894px;
}

.secondary-table {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: var(--backgroundBase);
  bottom: -1600px;
  opacity: 0;
  transition: 0.3s;
  border-collapse: collapse;

  display: flex;
  flex-direction: column;
  &--open {
    bottom: 0;
    opacity: 1;
    // z-index: 1;
  }
}

.sessions-table-container {
  width: 100%;
  height: 70%;
  overflow: scroll;
}
// .sessions-table-container::-webkit-scrollbar {
//   display: none;
// }
.sessions-table-container::-webkit-scrollbar {
  width: 14px;
}

.sessions-table-container::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.sessions-table-container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.sessions-table-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.sessions-table {
  // background-color: var(--backgroundBase);
  // font-size: 14px;
  border-collapse: collapse;
  // display: block;
  // thead {

  thead {
    tr {
      background-color: var(--backgroundBase);
    }
  }
  // tr{width:}
  // tr {
  //   height: 74px;
  // }
  td,
  th {
    border: 1px solid var(--backgroundBase);
    text-align: left;
    padding: 8px;
    width: 200px !important;
    height: 74px;
    white-space: nowrap;
    // display: block;
  }

  // tbody {
  //   td {
  //     border: 1px solid white;
  //   }
  // }
  // }
  tr:nth-child(even) {
    background-color: var(--backgroundBase);
  }
}

.session-list-bar {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  gap: 40px;
  // text-align: center;
  // font-size: 14px;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
  border: 4px solid var(--backgroundBase);
  border-radius: 5px;
  margin: 0.25rem 0;
  button {
    padding: 0.5rem !important;
  }
  &__arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
      // width: 30px;
      // height: 30px;
    }
    i {
      font-size: 50px;
      line-height: 0.6;
    }
    .can-click:hover {
      color: var(--primary);
      cursor: pointer;
    }
    .can-click:active {
      color: #174f1e;
    }

    // justify-content: space-around;
  }
}

.action-list-container {
  // width: 100%;
  // height: 70%;
  // overflow: scroll;
}
// .action-list-container::-webkit-scrollbar {
//   display: none;
// }
.action-list-container::-webkit-scrollbar {
  width: 14px;
}

.action-list-container::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.action-list-container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.action-list-container::-webkit-scrollbar-corner {
  background-color: transparent;
}

.action-list-bar {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  // gap: 40px;
  // text-align: center;
  // font-size: 14px;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
  // border: 4px solid var(--backgroundBase);
  border-radius: 5px;
  border: 1px solid var(--backgroundBase);
  // margin: 0.25rem 0;
  button {
    padding: 0.5rem !important;
  }
  &__arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80px;
    height: 68px;
    // background-color: var(--backgroundSecond);
    // border-right: 1px solid var(--backgroundBase);
    // border-radius: 5px;
    i {
      font-size: 50px;
      line-height: 0.6;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: var(--backgroundBase);
    }
    .can-click:hover {
      color: var(--primary);
      cursor: pointer;
    }
    .can-click:active {
      color: #174f1e;
    }

    // justify-content: space-around;
  }
  &__selectable {
    display: flex;
    align-items: center;
    padding-inline: 2rem;
    width: 100%;
    height: 68px;
    gap: 2rem;
    // background-color: var(--secondary);
    // justify-content: space-between;
  }
  &__selectable:hover {
    background-color: rgb(11, 17, 32);
    cursor: pointer;
  }
}
.action-list-bar:nth-child(odd) {
  background-color: var(--backgroundBase);
}
