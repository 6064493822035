body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Overrides for MUI */
.MuiDataGrid-columnHeaders {
  background-color: #3AB549;
  color: #ffffff;
}

.MuiDataGrid-columnHeaders svg {
  fill: #ffffff;
}

.MuiDataGrid-footerContainer {
  background-color: #ffffff;
}

.blue {
  background-color: #0071BB !important;
}

.green {
  background-color: #3AB549 !important;
}

.green_icon {
  color: var(--dark-pastel-green);
}

.odd_row {
  background-color: #FFFFFF;
}

.even_row {
  background-color: #E9EDF4;
}

.whiteicon svg {
  fill: #ffffff;
}

.blueicon svg {
  fill: #0071BB;
}

.greenicon svg {
  fill: #3AB549;
}

span.dark_pastel_green>svg {
  color: var(--dark-pastel-green);
}

/* Video Test */
#hgbgvid {
  width: 100%;
  height: 400px;
  object-fit: cover;
  left: 0;
  top: 0;
  position: absolute;
  /* z-index: -10; */
}

/* OLD CSS FROM ORIG ALPHA */
/* body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
} */

h1,
h2,
p,
ul,
li {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1 {
  color: #0071bd;
}

#root {
  width: 100%;
  height: 100vh;
}

/* .site {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  align-content: stretch;
} */



div.header {
  flex: 1 0 auto;
  width: 100%;
  background-color: #ffffffcc;
  min-height: 60px;
  max-height: 60px;
  position: relative;
  z-index: 1;
}

div.header_content {
  display: flex;
  width: 100%;
  max-width: 950px;
  margin: 0px auto;
}

div.header_content>div.logo {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;

}

img.handy-golf-logo {
  height: 40px;

  display: block;
  margin: auto auto;
  vertical-align: middle;
}
img.handy-golf-logo-dark-mode {
  /* height: 40px; */
  /* no height is more accurate in app side, temp change here */
  display: block;
  margin: auto auto;
  vertical-align: middle;
}

div.header_menu {
  margin: 0 auto;
  padding: 0 10px;
  display: none;
  width: 100%;
  justify-content: right;
}

div.header_menu>div {
  margin: auto 0;
}

a.header_menu {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: var(--dim-gray);
  font-weight: bold;
  text-decoration: none;
  border-radius: 25px;
  padding: 8px 16px;
}

a.header_menu:hover {
  /*background-color: #dedede;*/
  color: var(--azul);
  text-underline-offset: 10px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

a.header_menu.active {
  /*background-color: #3ab54b;*/
  color: var(--azul);
  text-underline-offset: 10px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

a.lesson_unit_selection {
  position: relative;
  left: -16px;
  padding: 6px 12px;
  text-decoration: none;
  color: var(--dim-gray);
}

a.lesson_unit_selection:hover {
  background-color: var(--dark-pastel-green);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--white);
  font-weight: bold;
}

a.lesson_unit_selection.active {
  background-color: var(--dark-pastel-green);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--white);
  font-weight: bold;
}

div.lesson_unit_selection {
  display: flex;
  width: 100%;
}

div.lesson_unit_selection>a {
  flex: 1;
  align-self: center;
}

div.lesson_unit_selection>div {
  flex: 1;
  max-width: 16px;
  margin: auto;
}

svg.lesson_unit_notes {
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  color: var(--alice-blue);
}

svg.lesson_unit_notes.has_notes {
  color: var(--azul);
}

svg.lesson_unit_note_delete {
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  color: var(--dim-gray);
}

.lesson_feedback_stars .MuiSvgIcon-root {
  color: #faaf00;
}

div.sidemenu>div {
  display: block;
  margin: 20px;
}

div.sidemenu>div>a {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: #555555;
  text-decoration: none;
  border-radius: 25px;
  padding: 8px 16px;
}

div.sidemenu>div>a:hover {
  background-color: #dedede;
  color: #000000;
}

div.sidemenu>div>a.active {
  background-color: #3ab54b;
  color: #ffffff;
}

.blackshadow {
  text-shadow: var(--black) 1px 0 10px;
}

a.button1 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 25px;
  padding: 8px 16px;
  background-color: var(--dark-pastel-green);
}

a.button1:hover {
  background-color: var(--pakistan-green);
  text-decoration: underline;
}

button.green {
  background-color: var(--dark-pastel-green);
}

button.google_sign_in {
  width: 100%;
  margin-bottom: 20px;
}

button.apple_sign_in {
  width: 100%;
  margin-bottom: 20px;
  background-color: var(--black) !important;
  color: var(--white);
}


div.header_content>div.account {
  margin-left: 10px;
  padding-left: 20px;
  display: none;
}

div.header_content>div.hamburger {
  margin-left: auto;
  display: flex;
}

div.body {
  display: flex;
  flex: 1 0 auto;
}

div.green_body {
  width: 100%;
  height: 100%;
  background-color: #f0f6f4;
}

div.body_content {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 26px;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

div.footer {
  flex: 1 0 auto;
  max-height: 60px;
  background-color: var(--dark-pastel-green);
}

div.footer_content {
  width: 100%;
  /* max-width: 950px; */
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
}

div.footer_content_left {
  width: 50%;
  color: var(--white);
  font-size: 11px;
  font-weight: normal;
}

div.footer_content_right {
  width: 50%;
  text-align: right;
  color: var(--white);
  font-size: 11px;
  font-weight: normal;
}

a.footer {
  color: var(--white);
  text-decoration: none;
  margin: 0 12px;
}

a.footer:hover {
  color: var(--jet);
  text-decoration: underline;
  margin: 0 12px;
}

div.black {
  background-color: var(--black);
  color: var(--white);
}

div.jet {
  background-color: var(--jet);
  color: var(--white);
}

div.dim-gray {
  background-color: var(--dim-gray);
  color: var(--white);
}

div.white {
  background-color: var(--white);
  color: var(--black);
}

div.pakistan-green {
  background-color: var(--pakistan-green);
  color: var(--white);
}

div.dark-pastel-green {
  background-color: var(--dark-pastel-green);
  color: var(--white);
}

div.penn-blue {
  background-color: var(--penn-blue);
  color: var(--white);
}

div.azul {
  background-color: var(--azul);
  color: var(--white);
}

div.alice-blue {
  background-color: var(--alice-blue);
  color: var(--black);
}

div.square_small {
  display: block;
  width: 30px;
  height: 30px;
}

div.square_medium {
  display: block;
  width: 90px;
  height: 90px;
}

div.square_large {
  display: block;
  width: 150px;
  height: 150px;
}

.txt_center {
  text-align: center;
}


img.lesson-img {
  width: 400px;
  height: 300px;
}

@media only screen and (min-width: 768px) {
  div.header_menu {
    display: flex;
  }

  div.header_content>div.account {
    display: flex;
  }

  div.header_content>div.hamburger {
    display: none;
  }
}

@media screen and (max-width:1080px) {

  /* div.footer{


  } */
  div.footer_content {
    position: fixed;
    bottom: 0%;
    background-image: url(/public/img/foottest.jpeg);
    background-position-x: 50%;
    background-repeat: no-repeat;

    background-color: white;

  }

  div.footer_content_left {
    display: none;
  }

  div.footer_content_right {
    display: none;
  }
}


/* Font Colors */
p.txt-dark-pastel-green,
span.txt-dark-pastel-green {
  color: var(--dark-pastel-green);
}

span.txt-pakistan-green {
  color: var(--pakistan-green);
}

/* Andrei's CSS */
:root {
  /* Coolors Exported Palette - https://coolors.co/010101-363636-707070-ffffff-134611-3ab549-091540-0071bb-e9edf4 */

  /* CSS HEX */
  --black: #010101ff;
  --jet: #363636ff;
  --dim-gray: #707070ff;
  --white: #ffffffff;
  --pakistan-green: #134611ff;
  --dark-pastel-green: #3ab549ff;
  --penn-blue: #091540ff;
  --azul: #0071bbff;
  --alice-blue: #e9edf4ff;
  --unnamed_green_bg: #f0f6f4ff;

  /* SCSS Gradient */
  --gradient-top: linear-gradient(0deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-right: linear-gradient(90deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-bottom: linear-gradient(180deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-left: linear-gradient(270deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-top-right: linear-gradient(45deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-bottom-right: linear-gradient(135deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-top-left: linear-gradient(225deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-bottom-left: linear-gradient(315deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
  --gradient-radial: radial-gradient(#010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
}