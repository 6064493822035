.landing{
    width: 100%;
    height: 100%;
    background-color: #1A1A2E;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.landing-background{
    width:3840px;
    height: 100vh; 
    background-image: url('/public/img/comingsoon/background-full.jpg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    display: flex;
    justify-content: center;
}
.landing-content{
    width: 100%;
    height:100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.info-and-image{
    display: flex;
    max-width: 1717.81px;
    width: 100%;

}
.info-half{
    width: 50%;
    display: flex;
    justify-content: center;

}
.info-half-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 74.5px;
    gap:130px;
    
    align-items: center;
}
.logo-section{
    width: 50%;
    min-width: 300px;
}
.logo-img{
    width: 100%;
}
.social-icons-section{
    display: flex;
    width: 43.07%;
    min-width: 300px;
    justify-content: space-between;

}
.link-img{
    transition: .2s;
}
.link-img:hover{
    cursor: pointer;
    opacity: .8;
}

.download-section{
    width: 47.71%;
    min-width: 300px;
}
.download-buttons{
 display: flex;
 justify-content: space-between;   
 width: 100%;
 margin-bottom: 30px;
}
.download-img{
    width:47.04%;
}

.tagline{
    width: 100%;
   display: flex;
   justify-content: center;
}
.tagline-text{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    white-space: nowrap;
}


.image-half{
    width: 50%;
    max-height: 82.4%;

}
.ross-not-centered{
    display: block;
}
.ross-centered{
    display:none;
}
.image-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.golfer-img{
    max-width: 100%;
    max-height: 100vh;
    height: auto;
    width: auto;
    object-fit: contain;

}

/* only for narrow device */
.image-container-centered{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.golfer-img-centered{
    width: 100%;

}



/* MEDIA QUERIES */
@media (max-width: 1724px){
    .info-half-content{
        margin-top: 0;
        gap:0;
        justify-content: space-evenly;
    }
}

@media (max-width: 1400px) {

    .tagline-text{
        font-size: 21px;
    }

}

@media screen and (orientation:portrait) {
    .landing-content{
        flex-direction: column;
   
    }
    .ross-not-centered{
        display: none;
    }
    .ross-centered{
        height: 50%;
        width: 100%;
        display: block;
    }
    .info-and-image{
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    
    }
    .info-half{
        height: 50%;
        width:100%
    }
    .golfer-img-centered{
        max-height: 50vh;
        height: auto;
        width: auto;
    }
  
}

