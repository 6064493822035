$black: #010101;
$jet: #363636;
$dim-gray: #707070;
$white: #ffffff;
$pakistan-green: #134611;
$dark-pastel-green: #3ab549;
$penn-blue: #091540;
$azul: #0071bb;
$alice-blue: #e9edf4;
$unnamed_green_bg: #f0f6f4;

$hg-primary: $dark-pastel-green;
$hg-secondary: $azul;

// $theme-background: --theme-background;
// $theme-secondary: --theme-secondary;

// $--theme-primary: --theme-primary;
// $--theme-secondary: --theme-secondary;
// $--theme-surface: --theme-surface;
// $--theme-background: --theme-background;
// $--theme-on-primary: --theme-on-primary;
// $--theme-on-secondary: --theme-on-secondary;
// $--theme-on-surface: --theme-on-surface;
// $theme-text-color: --theme-text-color;

// $theme-light: (
//   $theme-background: $white,
//   $theme-secondary: $unnamed_green_bg,
//   $theme-text-color: $black,
// );
// $theme-dark: (
//   $theme-background: #011523,
//   $theme-secondary: #010d15,
//   $theme-text-color: $white,
// );

// --black: #010101ff;
// --jet: #363636ff;
// --dim-gray: #707070ff;
// --white: #ffffffff;
// --pakistan-green: #134611ff;
// --dark-pastel-green: #3ab549ff;
// --penn-blue: #091540ff;
// --azul: #0071bbff;
// --alice-blue: #e9edf4ff;
// --unnamed_green_bg: #f0f6f4ff;

// --gradient-top: linear-gradient(0deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-right: linear-gradient(90deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-bottom: linear-gradient(180deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-left: linear-gradient(270deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-top-right: linear-gradient(45deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-bottom-right: linear-gradient(135deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-top-left: linear-gradient(225deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-bottom-left: linear-gradient(315deg, #010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
// --gradient-radial: radial-gradient(#010101ff, #363636ff, #707070ff, #ffffffff, #134611ff, #3ab549ff, #091540ff, #0071bbff, #e9edf4ff);
