.search-bar {
  // width: 100%;
  //   display: flex;
  //   justify-content: center;
  position: relative;
  input[type="search"] {
    outline: 0;
    width: 100%;
    height: 36px;
    background: #7676803d 0% 0% no-repeat padding-box;
    border: 0;
    border-radius: 10px;
    text-indent: 2.5rem;
    color: #ebebf599;
    // appearance: none;
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 6px;
    left: 6px;
    color: #ebebf599;
  }
  //   button {
  //     display: none;
  //   }
  //   input:not(:placeholder-shown) {
  // display: block;
  //   }
  ::-webkit-search-cancel-button {
    // position: absolute;
    // right: 1px;
    display: none;
  }
}
