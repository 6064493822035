@use "../utils/" as *;

.wide {
  background-color: #010d15;
  // width: 986px;
  // height: 326px;
  margin-top: 2rem;
  //margin inline should be taken out once you figure out the card grid container
  margin-inline: 1.5rem;
  display: flex;
  flex-direction: column;
  color: $hg-primary;
  h2 {
    margin: 0;
    color: white;
  }
  p {
    color: white;
  }
}

.thumbnail {
  // width: 264px;
  // aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -40px 3em 0.1em #000;
  border-radius: 3px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  p {
    // box-shadow: inset 10px 10px 10px 0.5em #000;
    padding-left: 10px;
  }
}
.square-thumbnail-card {
  display: flex;
  justify-content: space-evenly;
  max-width: 320px;
  gap: 1rem;
  min-width: 300px;

  &__thumb {
    width: 100px;
    height: 100px;
    // box-shadow: inset 0 -5px 2em 5px #000;
    border-radius: 3px;
    background-position: center;
    background-size: cover;
  }
  &__textbox {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 8px;
    width: 60%;
    // width: 200px;
    font-size: 14px;
  }
}

.borderless {
  // width: 100%;
  height: 365px;
  // margin: 0 15px;

  box-shadow: inset 0 0 5em 0.5em #000;
  transition: 0.3s;
  // border-radius: 3px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  &__title-box {
    margin: 0 1rem 0 2rem;
    font-weight: bold;
    font-size: 23px;
    p {
      font-weight: 400;
      font-size: 14px;
      margin-top: 0;
    }
    // margin: 14px 0 0 0;
  }
}
.borderless:hover {
  // background-position: ;
  cursor: pointer;
  box-shadow: inset 0 0 3em 0.5em #000;
}
//TODO, REMAKE THE .card CLASS FOLLOWING THE MODELS ABOVE
// .bordered {
//   display: flex;
//   flex-direction: column;
//   border-radius: 10px;
//   width: 318px;
//   // margin: 15px;
//   margin: 0 15px;
//   height: 100%;
// }

.card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  // width: 318px;
  width: 100%;
  // margin: 15px;
  // margin: 0 15px;
  // height: 100%;
  // transition: 1s;
  p {
    font-size: 14px;
    margin-top: 0;
  }
  img {
    max-width: 100%;
    border-radius: 5px;
    vertical-align: middle;
    // transition: 1s;
  }
  i {
    position: absolute;
    margin-top: 4px;
    margin-left: -28px;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }
  &--continue {
    background-color: $hg-primary;

    p {
      color: $pakistan-green;
    }
  }
  &--start {
    background-color: $hg-secondary;
  }
  &--purchase {
    background-color: $dim-gray;
    p {
      color: $white;
    }
  }
  &__title {
    margin: 14px 0 0 0;
    font-weight: bold;
    font-size: 23px;
  }

  &--bordered {
    padding: 22px;
  }
  &--split {
    padding-bottom: 22px;
    .card__title {
      margin: 14px 10px 0 10px;
    }
    p {
      margin-left: 10px;
      margin-right: 10px;
    }
    button {
      margin: 10px 30px;
    }
  }

  &__info {
    // padding-top: 4px;

    img {
      display: none;
    }

    button {
      display: none;
    }
  }
}

.fbcard {
  background-color: white;
  display: flex;
  padding: 2rem 0;
  height: 100%;
  white-space: normal;
  justify-content: space-evenly;

  &__photo {
    border: 1px dotted #3ab549;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50%;
      width: 140px;
      height: 140px;
    }
  }
  &__text {
    text-align: left;
    width: 40vw;
  }
}

// .card2temp {
//   background-color: $theme-secondary;
// }

@media (max-width: 560px) {
  .borderless {
    height: 200px;
  }
}
