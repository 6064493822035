.container {
  // padding: 1rem;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  width: 100%;
  // padding: 0 2rem;
  // margin-left: 6%;
  // padding-top: 150px;
  height: 100%;
  margin-top: -150px;

  &__content {
    width: 1080px;
    height: 100%;
    overflow-y: scroll;
    padding-top: 150px;
  }
  &__side-content {
    display: flex;
    justify-content: center;
    width: 420px;
    height: 100%;
    overflow-y: scroll;
    padding-top: 150px;
    &--admin {
      width: 600px;
    }
  }
  &__bottom-content {
    display: flex;
    justify-content: center;
    .only-on-mobile {
      width: 100vw;
    }
    .not-on-mobile {
      width: 100%;
      .accordion {
        padding: 30px 0;
        width: 100%;
        &__unit {
          width: 100%;
        }
      }
    }
  }

  &__collapse {
    max-width: 420px;
    flex-grow: 1;
  }
}
.no-scroll-container {
  margin-top: 0;
  padding-top: 0;
}

.container__triple-wide-content {
  display: flex;
  background-color: #010d15;
  height: 300px;
  margin-top: 48px;
  padding-inline: 30px;
  gap: 30px;
}
.container__color-layer {
  background-color: var(--backgroundSecond);
}

//SCROLLBAR
.container__content::-webkit-scrollbar,
.container__side-content::-webkit-scrollbar {
  display: none;
}

.pad-box {
  padding-inline: 30px;
  // padding: 0 30px;
}
.video-pad-box {
  padding-inline: 30px;
}
.card-container {
  display: grid;

  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

  grid-auto-rows: auto;
}
@media (max-width: 1079px) {
  .scroll-content-mobile {
    height: 100%;
    overflow-y: scroll;
  }
  .scroll-content-mobile::-webkit-scrollbar {
    display: none;
  }
  .container__content {
    overflow-y: hidden;
  }
  .container {
    padding: 0 0 100px 0;
  }
  .container__bottom-content .not-on-mobile .accordion {
    padding: 1rem 0;
  }
  .pad-box {
    padding: 0 1rem;
  }
}
// @media(min-width: 1080px){

// }

@media (max-width: 560px) {
  .container {
    padding: 0;
  }
  .container__triple-wide-content {
    display: none;
  }
}
