.note-section {
  &__add-note {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }
  &__note {
    display: flex;
    flex-direction: column;
    margin: 4rem 0;
    .material-icons {
      color: #707070;
    }
    &__bottom {
      display: flex;
      align-items: center;
      gap: 22px;
      margin: 16px 0 0 0;

      &--editing {
        justify-content: space-between;
        gap: 0;
      }
    }
  }
  &__divider {
    width: 100%;
    border-top: 1px solid var(--fontColor);
    opacity: 0.53;
    &--focused {
      width: 100%;
      border: 1px solid var(--fontColor);
      opacity: 1;
    }
  }
  &__text-area {
    color: rgba(255, 255, 255, 0.526);
    background-color: var(--backgroundBase);
    height: 2rem;
    padding-bottom: 26px;
    border: none;
    resize: none;
    box-shadow: none;
    outline: none;
    // border-top: none;
    // border-left: none;
    // border-right: none;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.534);
  }
  &__text-area:focus {
    color: var(--fontColor);
    background-color: var(--backgroundBase);
    // border: none;
    // border-bottom: 1px solid white;
    resize: none;
    box-shadow: none;
    outline: none;
    // height: 2.5rem;
    // .note-section__divider {
    //   opacity: 1;
    // }
  }
}

.note-section__text-area::-webkit-scrollbar {
  display: none;
}
