img.lesson_list_top {
    height: 300px;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -10;
    object-fit: cover;
}
div.gray_border {
    border-left: solid 4px var(--alice-blue);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
div.lesson_list {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    min-width: 700px;
}
div.lesson {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
}
div.lesson_top_section {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}
div.lesson_bottom_section {
    margin: 20px;
}
div.lesson_video {
    flex: 2;
    margin-right: 10px;
    position: relative;
    /*max-height: 265px;*/
    margin-left: 50px;
}
div.lesson_video_text {
    position: absolute;
    top: 175px;
    left: 25px;
}
div.lesson_video_text > div.name {
    color: var(--white);
    font-weight: bold;
    font-size: 20px;
    text-shadow: var(--black) 1px 0 10px;
}
div.lesson_video_text > hr {
    max-width: 60px;
    margin-left: 0;
    border: none;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--dark-pastel-green);
    padding: 0;
}
div.lesson_video_text > div.callout {
    color: var(--white);
    font-size: 14px;
    text-shadow: var(--black) 1px 0 10px;
}
div.lesson_video > img {
    border-radius: 10px;
    width: 450px;
}
div.lesson_units {
    flex: 1;
    min-width: 280px;
    max-width: 280px;
    /*max-height: 300px;*/
    position: relative;

}
div.lesson_unit {
    margin-bottom:10px;
}

div.unit_progress_border_complete {
    background-color: var(--dark-pastel-green);
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}
div.lesson_bg {
    position: absolute;
    background-color: var(--unnamed_green_bg);
    border-radius: 10px;
    width: 80%;
    height: 300px;
    top: -20px;
    right: -20px;
    z-index:-100;
}
div.tab_content_box {
    background-color: var(--white);
    padding: 10px 20px;
}
div.lesson_progress_title {
    margin-left: 50px;
    font-size: 20px;
    color: var(--dark-pastel-green);
    font-weight: bold;
}
div.unit_controls {
    display: flex;
    margin-top: 10px;
}
button.back_button {
    flex: 1;
    max-width: 30px;
}
div.unit_title {
    flex: 1;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
}
button.forward_button {
    flex: 1;
    max-width: 30px;
}

/* MUI */
div.MuiPaper-root.completed_unit {
    background-color: var(--dark-pastel-green);
    color: var(--white);
}
div.MuiPaper-root {
    box-shadow: none;
}
/*div.MuiPaper-root.completed_unit > svg {*/
div.completed_unit > div > div > svg.MuiSvgIcon-root {
    color: var(--white);
}
svg.completed_check {
    margin-right: 20px;
}
div.Mui-expanded {
    margin: 0 !important;
}
.MuiAccordionSummary-root {
    min-height: 40px !important;
    max-height: 40px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
    max-height: 40px !important;
}
.MuiAccordionSummary-root {
    font-size: 14px;
}
.MuiCollapse-root {
    background-color: var(--white);
    color: var(--black);
    font-size: 12px;
}
.MuiLinearProgress-root.greenbg {
    background-color: var(--unnamed_green_bg);
}
.MuiLinearProgress-root.whitebg {
    background-color: var(--white);
}
.MuiLinearProgress-root.greenbg > .MuiLinearProgress-bar {
    background-color: var(--dark-pastel-green);
}
.MuiLinearProgress-root.whitebg > .MuiLinearProgress-bar {
    background-color: var(--dark-pastel-green);
}
button.hg_lesson_tab {
    text-transform: none;
}
button.Mui-selected.hg_lesson_tab {
    background-color: var(--white);
}
button.lesson_title {
    text-transform: none;
    color: var(--dark-pastel-green);
    font-weight: bold;
    font-size: 20px;
}
button.white_button {
    background-color: var(--white);
    color: var(--pakistan-green);
}
button.blue_rounded {
    text-transform: none;
    border-radius: 20px;
}



@media screen and (max-width:768px) {
    div.lesson_top_section {
        display: flex;
       flex-direction: column-reverse;

    }
    div.lesson_units{
        max-width: 100%;
    }
    div.lesson_video{
        margin:0;
    }
    div.body_content{
        padding:0
    }
}