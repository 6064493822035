.feedback {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 4rem;
  background-size: cover;
  background-position: 50%;
  background-image: linear-gradient(
      90deg,
      rgba(20, 28, 22, 0.88) 100%,
      rgba(0, 0, 0, 0) 100%,
      rgba(21, 30, 22, 0.2) 100%
    ),
    url(../../../public/img/course-list-top-background.jpg) !important;

  &__title {
    h1 {
      color: white;
      margin: 0;
    }
    p {
      color: #3ab549;
      margin: 0;
    }
  }

  &__carouselWrapper {
    display: flex;
    justify-content: center;
    margin: 2rem;
  }
}
