@use "../utils/" as *;
.accordion {
  .material-icons {
    font-weight: bold;
  }
  &__unit {
    width: 360px;
    // height: 60px;
    margin: 0 0 30px 0;

    border-radius: 10px;
    background-color: #384249;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    user-select: none;
  }
  &__title-box {
    padding-inline: 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .material-icons {
      transition: 0.2s;
    }
    .material-icons.open {
      transform: rotate(90deg);
    }
    p {
      font-size: 18px;
    }
  }
  &__data-box {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-out;
    &--open {
      grid-template-rows: 1fr;
      padding-bottom: 16px;
    }
  }

  &__sub-unit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-inline: 20px;
    // cursor: pointer;
    margin: 8px 0;
    p {
      margin: 0 24px;
    }
    &__data {
      display: flex;
      align-items: center;
      padding: 8px 0 8px 0;

      width: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      cursor: pointer;
      position: relative;
    }
    &__data:hover {
      background-color: #22282c;
      // background-color: $hg-primary;
    }
    &__data.selected {
      transition: 0.3s;
      background-color: $hg-primary;
      position: relative;
    }

    &__checkbox {
      padding-inline: 12px;
      input {
        accent-color: $hg-primary;
        cursor: pointer;
      }
    }
  }
  // &__sub-unit:hover {
  //   background-color: #20262a;
  // }
}

.accordion-mobile {
  .material-icons {
    font-weight: bold;
  }
  &__unit {
    width: 100%;

    border-radius: 10px;
    background-color: #384249;

    user-select: none;
  }
  &__title-box {
    padding-inline: 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .material-icons {
      transition: 0.2s;
    }
    .material-icons.open {
      transform: rotate(90deg);
    }
  }

  &__data-box {
    height: 216px;
  }

  &__sub-unit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 8px 0;
    p {
      margin: 0 24px;
    }
    &__data {
      display: flex;
      align-items: center;
      padding: 8px 0 8px 20px;

      width: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      cursor: pointer;
      position: relative;
    }
    &__data:hover {
      background-color: #22282c;
      // background-color: $hg-primary;
    }
    &__data.selected {
      transition: 0.3s;
      background-color: $hg-primary;
      position: relative;
    }

    &__checkbox {
      padding-inline: 12px;
      input {
        accent-color: $hg-primary;
        cursor: pointer;
      }
    }
  }
}
.accordion-swiper {
}
.swiper {
  height: 320px;
  margin: 20px 0;
}
.swiper-slide {
  // padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination {
  // margin-bottom: 80px;
  bottom: 0px !important;
}
.swiper-pagination-bullet {
  height: 10px !important;
  width: 10px !important;
  background: white !important;

  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: $hg-primary !important;
  width: 32px !important;
  border-radius: 6px !important;
}
