@use "../utils/" as *;
.navigation__side {
  min-width: 420px;

  .logo {
    margin-left: -35px;
  }
  height: 100vh;
  padding-top: 54px;
  &__navlink {
    display: flex;
    margin-top: 54px;
    padding: 0 0 0 100px;
    // padding: 0 40px;
    // justify-content: center;

    a {
      font-size: 24px;
      font-weight: normal;
      width: 100%;
      text-decoration: none;
      color: $dim-gray;

      span {
        display: flex;
        width: 60px;
      }
      .nav-active {
        display: none;
      }
    }

    a.active {
      color: $hg-primary;
      font-weight: bold;
      .nav-active {
        display: block;
      }
      .nav-inactive {
        display: none;
      }
    }
    // .trainer-sub-nav--hidden {
    //   // transition: 1s;
    //   transform: translateY(-40px);
    // }
    // .trainer-sub-nav {
    //   transition: 0.2s;
    //   // transform: translateY(40px);
    // }
  }
}
.side-nav-expand {
  .material-icons {
    transition: 0.2s;
  }
  .material-icons.open {
    transform: rotate(180deg);
  }
}
.trainer-sub-nav {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;
  // margin-top: 54px;
  padding: 0 0 0 100px;
  a {
    font-size: 24px;
    font-weight: normal;
    width: 100%;
    text-decoration: none;
    color: $dim-gray;
    // padding-top: 50px;

    span {
      display: flex;
      width: 60px;
    }
    .nav-active {
      display: none;
    }
  }

  a.active {
    color: $hg-primary;
    font-weight: bold;
    .nav-active {
      display: block;
    }
    .nav-inactive {
      display: none;
    }
  }
  &--open {
    // margin-top: 54px;
    grid-template-rows: 1fr;
    padding-bottom: 16px;
  }

  &__gap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    gap: 2rem;
    &--open {
      margin-top: 2rem;
    }
  }
}

.navigation__bottom {
  position: fixed;
  bottom: 0%;
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  background-color: var(--backgroundBase);
  height: 100px;
  z-index: 9;

  &__navlink {
    display: flex;
    flex-direction: column;

    justify-content: center;

    a {
      font-size: 12px;
      font-weight: normal;
      width: 100%;
      text-decoration: none;
      color: $dim-gray;

      span {
        display: flex;
        height: 40px;
      }
      .nav-active {
        display: none;
      }
    }

    a.active {
      color: $hg-primary;
      font-weight: bold;
      .nav-active {
        display: block;
      }
      .nav-inactive {
        display: none;
      }
    }
    .trainer-sub-nav--hidden {
      // transition: 1s;
      transform: translateY(-40px);
    }
    .trainer-sub-nav {
      transition: 0.2s;
      // transform: translateY(40px);
    }
  }
}
.seethrough {
  background-color: rgba(0, 0, 0, 0);
}
.navigation__top-app__wrapper {
  display: flex;
  height: 150px;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}
.navigation__top-app {
  span {
    display: flex;
  }
  display: flex;

  width: 100%;
  align-items: center;
  justify-content: center;

  height: 100%;
  display: flex;
  justify-content: center;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); //for safari
  &__middle {
    width: 72%;

    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--search {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      width: 60%;
      gap: 20px;
      .search-bar {
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    width: 28%;
    padding: 0 30px;

    span {
      display: flex;
    }
  }

  &__popover-content {
    display: flex;
    background-color: #000509;
    flex-direction: column;
    color: white;
  }
}

//ADMIN NAV
.admin-nav-wrapper {
  width: 100vw;
  background-color: var(--backgroundBase);
  display: flex;
  justify-content: center;
  // margin-bottom: 20px;
}
.admin-navigation {
  // min-width: 240px;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 70px;

  padding: 20px 0;

  background-color: var(--backgroundBase);
  .material-icons {
    transition: 273ms;
  }
  .material-icons.open {
    transform: rotate(90deg);
  }
  &__navlink {
    display: flex;
    width: 100%;
    // margin-top: 54px;
    // padding: 0 0 0 40px;
    // padding: 0 40px;
    // justify-content: center;

    a {
      font-size: 24px;
      font-weight: normal;

      text-decoration: none;
      color: $dim-gray;
      white-space: nowrap;
      width: 100%;
      height: 100%;

      span {
        display: flex;
        width: 60px;
      }
      .nav-active {
        display: none;
      }
    }

    a.active {
      color: $hg-primary;
      font-weight: bold;
      .nav-active {
        display: block;
      }
      .nav-inactive {
        display: none;
      }
    }
    // .trainer-sub-nav--hidden {
    //   // transition: 1s;
    //   transform: translateY(-40px);
    // }
    // .trainer-sub-nav {
    //   transition: 0.2s;
    //   // transform: translateY(40px);
    // }
  }
}
.admin-navigation-navbox {
  display: flex;
  flex-direction: column;
}
.admin-navigation-dropdown {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;

  &--open {
    grid-template-rows: 1fr;
    padding-bottom: 16px;
  }
}
.admin-navigation-mobile {
  display: flex;
  justify-content: center;
  .material-icons {
    transition: 273ms;
  }
  .material-icons.open {
    transform: rotate(90deg);
  }
}

@media (max-width: 560px) {
  .navigation__top-app__wrapper {
    height: 54px;
  }
}
