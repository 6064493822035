.carousel {
  position: relative;
  display: flex;
  vertical-align: middle;
  // justify-content: center;
  width: 60vw;
  align-items: center;
  &__display {
    touch-action: none;
    border-radius: 6px;
    width: 55vw;
    height: 216px;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    // background-color: blueviolet;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
    z-index: 1;
    caret-color: transparent;
    &__element {
      touch-action: none;
      display: inline-block;
      vertical-align: top;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &__element {
    height: 100%;
    width: 100%;
    touch-action: none;
    display: inline-block;
    vertical-align: top;
    transition: 300ms linear;
  }
  &__left {
    position: absolute;
    left: 1%;
    padding: 0 2px;
    color: #e5faff;
    // user-select: none;
    z-index: 2;
    :hover {
      color: #f9f9f9;
      cursor: pointer;
    }
    :active {
      color: #b7f1ff;
    }
  }
  &__right {
    position: absolute;
    right: 1%;
    padding: 0 2px;
    color: #e5faff;
    // user-select: none;
    z-index: 2;
    :hover {
      color: #f9f9f9;
      cursor: pointer;
    }
    :active {
      color: #b7f1ff;
    }
  }
}

.carousel__element.active {
  opacity: 1;
}
.carousel__element.prev {
  opacity: 0;
}
.carousel__element.next {
  opacity: 0;
}
